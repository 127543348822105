import React from 'react';
import { Box, Heading, Text, Container } from '@chakra-ui/react';
import bg from '../images/bg.jpeg';
import Footer from '../components/Footer';
import Sponsor from '../images/Sponsors.png';
import Sponsors3 from '../images/Sponsors3.png';

const pageStyles = {
    background: `url(${bg})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    width: '100vw',
    minHeight: '100vh', // Ensure the background covers all content

};

const containerStyles = {
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
};

const Sponsors = () => {
  return (
    <div style={pageStyles}>
      <Container  maxW="xl" centerContent>
        <Box p="7">
          <Heading as="h1" fontSize={['2vw', '2vw', '2vw']} textAlign="center" color="#A9CD56">
            Sponsors
          </Heading>
          <Text fontSize={['1vw', '1vw', '1vw']}  fontWeight= 'bold'mt="4" textAlign="center" textColor='gray.700'>
            We would like to thank our sponsors for their support!
          </Text>
        </Box>
      </Container >
      <Box  h="50vh" display="flex" alignItems="center" justifyContent="center">
        <Container  style={{ ...containerStyles, backgroundImage: `url(${Sponsors3})` }} maxW="60%" h="70%" position="relative">
        </Container>

      </Box>
      <Footer />
    </div>
  );
};

export default Sponsors;
