import React from 'react';
import { Box, Text, SimpleGrid, Flex, Avatar, Wrap, WrapItem } from '@chakra-ui/react';

const SimpleGridWrapper = (props) => {
  return (
    <SimpleGrid
      minChildWidth="270px"
      mb={[5, 10, 10]}
      spacing={[5, 10, 10]}
      columns={[1, 1, 2, 4]}
      mt="20px"
      justifyContent="center"
      alignContent="center"
      display="flex"
      flexWrap="wrap"
    >
      {props.teamInformation.map((teamMember, index) => (
        <Box key={index} height="500px" width="370px" alignContent="center">
          <Flex
            direction="column"
            alignItems="center"
            justifyContent="center"
            height="100%"
          >
            <Wrap>
              <WrapItem>
                <Avatar size="2xl" name={teamMember.name} src={teamMember.avatarSrc} />
              </WrapItem>
            </Wrap>
            <Text fontSize="xl" color="#2A4365" fontWeight="bold" textAlign="center">
              {teamMember.name}
            </Text>
            <Text fontSize="sm" color="#424242" fontWeight="normal" textAlign="center">
              {teamMember.role}
            </Text>
            <Box
              bg="#EDFBE7"
              height="120px"
              width="350px"
              borderRadius={15}
              boxShadow="md"
              mt={4}
              p={4}
            >
              <Text color="#2A4365">
                 {teamMember.Description}
              </Text>
            </Box>
          </Flex>
        </Box>
      ))}
    </SimpleGrid>
  );
}

export default SimpleGridWrapper;
