import React from 'react';
import { Box, Container, Text, Flex, Heading, Square, Spacer, } from '@chakra-ui/react';
import { Center } from '@chakra-ui/react';
import Ab1 from '../images/Ab1.webp';
import patient from '../images/patient.png';
import building from '../images/image.png';
import Footer from '../components/Footer';
import bg from '../images/bg.jpeg';

const pageStyles = {
  background: `url(${bg})`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  width: '100vw',
  minHeight: '100vh', // Ensure the background covers all content
};

const imageStyles = {
  width: '150px',
  height: '150px',
 
};

const About = () => {
  return (
    <Flex direction="column" minHeight="100vh" style={pageStyles}>
      <Flex direction="row" flex="1">
        <Box  flex="1" p={4}>
         

          <Text fontSize={['1.5vw', '1.5vw', '1.5vw']} fontWeight="bold" color="Black" maxW="100%" overflow="hidden">
            We Solve the Non-compliance to Home-prescribed Physiotherapy Exercises:
          </Text>
          <Text
            fontWeight="normal"
            fontFamily="sans-serif"
            color="blackAlpha.900"
            fontSize={['1vw', '1vw', '1vw']}
            textAlign="left"
            maxW="100%"
            overflow="hidden"
            whiteSpace="normal"
          >
            Cultivating patient accountability for performing home exercises, TelHab addresses non-compliance with prescribed home-based physiotherapy exercises
          </Text>
          <Flex direction="row" justify="space-between" mt ='40'>
            <Box  flex="1" p={4} textAlign="center">
              
              <Center  flexDirection="column" alignItems="center">
            <img src={patient} alt="Patient" style={imageStyles} />
            <Heading textAlign="left" fontSize={['1.5vw', '1.5vw', '1.5vw']} fontWeight="bold" color="black" mt={50}>
              Patients
              <Text fontSize={['1vw', '1vw', '1vw']} color="#424242" fontWeight="normal" textAlign="left">
              Telhab ensures patients receive ongoing support from their physiotherapists, offering education on exercises,
              addressing pain concerns, tracking recovery progress, and more.
            </Text>
            </Heading>
          </Center>
            </Box>
            <Box  flex="1" p={4} textAlign="center">
             
              <Center flexDirection="column" alignItems="center">
            <img src={building} alt="Building" style={imageStyles} />
            <Heading textAlign="left"fontSize={['1.5vw', '1.5vw', '1.5vw']} fontWeight="bold" color="black" mt={50}>
              Physio Clinics
              <Text fontSize={['1vw', '1vw', '1vw']} color="#424242" fontWeight="normal" textAlign="left">
              Telhab generates up to 20% additional revenue for physiotherapists and clinics while
              also assisting in enhancing patient loyalty.
            </Text>
            </Heading>
           
          </Center>
            </Box>
          </Flex>
        </Box>
        <Box  flex="1" p={4}>
          <Box
             bg={`url(${Ab1})`}
             backgroundSize="cover"
             backgroundPosition="center"
             width={['100%', '70%']}
             maxWidth={['100%', '100%']}
             p={2}
             mt={30}
             height={['700%', '100%']} // Use percentage values for responsiveness
             objectFit="cover"
             borderRadius="30px"
             border="2px solid grey.200"
             mx={['0', '20px']} // Margin-x for responsiveness
             ml={['0', '20px', '80px']} // Adjust marginLeft
          ></Box>
        </Box>
      </Flex>
      <Container 
      width ='100%'
      height= '200'
      ></Container>
      <Footer />
    </Flex>

  );
};

export default About;
