'use client'

import {
  Container,
  Flex,
  Box,
  Heading,
  Text,
  Button,
  VStack,
  Wrap,
  WrapItem,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Textarea,
  AspectRatio
} from '@chakra-ui/react'
import {
  MdPhone,
  MdEmail,
  MdLocationOn,
  MdOutlineEmail,
} from 'react-icons/md'
import Footer from '../components/Footer';
import bg from '../images/bg.jpeg';

const pageStyles = {
  background: `url(${bg})`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  width: '100vw',
  height: '120vh',
};

export default function Contact() {
  return (
    <div style={pageStyles}>
      <Container maxW="full" mt={0} centerContent overflow="hidden">
        <Flex>
          <Box
            color="white"
            borderRadius="lg"
            m={{ sm: 4, md: 16, lg: 10 }}
            p={{ sm: 5, md: 5, lg: 16 }}>
            <Box p={4}>
              <Wrap spacing={{ base: 20, sm: 3, md: 5, lg: 20 }}>
                <WrapItem>
                  <Box display="flex" flexDirection="column" alignItems="flex-start">
                    <Heading color="black">Contact</Heading>
                    <Text fontSize='lg' mt={{ sm: 4, md: 5, lg: 7 }} color="gray.500">
                      <Text fontSize="xl">Fill up the form below to contact</Text>
                    </Text>
                    <Box py={{ base: 5, sm: 5, md: 8, lg: 10 }}>
                      <VStack pl={0} spacing={3} alignItems="flex-start">
                        <Button
                          ml = {5}
                          size="lg"
                          height="48px"
                          width="180px"
                          variant="ghost"
                          color="#A9CD56"
                          _hover={{ border: '2px solid #1C6FEB' }}
                          leftIcon={<MdEmail color="#A9CD56" size="20px" />}>
                          <Text fontSize="xl">Contact@Telhab.com</Text>
                        </Button>
                        <Button
                          ml = {45}
                          size="lg"
                          height="48px"
                          width="375px"
                          variant="ghost"
                          color="#A9CD56"
                          _hover={{ border: '2px solid #1C6FEB' }}
                          leftIcon={<MdLocationOn color="#A9CD56" size="21px" />}>
                          <Text fontSize="xl">20 Dundas St W Suite 921, Toronto, ON M5G 2H1</Text>
                        </Button>
                      </VStack>
                    </Box>
                  </Box>
                </WrapItem>
                <WrapItem>
                  <Box bg="white" borderRadius="lg">
                    <Box m={8} color="#0B0E3F">
                      <VStack spacing={5}>
                        <FormControl id="name">
                          <FormLabel>Your Name:</FormLabel>
                          <InputGroup borderColor="#E0E1E7">
                            <Input type="text" size="md" />
                          </InputGroup>
                        </FormControl>
                        <FormControl id="name">
                          <FormLabel>Email:</FormLabel>
                          <InputGroup borderColor="#E0E1E7">
                            <InputLeftElement pointerEvents="none">
                              <MdOutlineEmail color="gray.800" />
                            </InputLeftElement>
                            <Input type="text" size="md" />
                          </InputGroup>
                        </FormControl>
                        <FormControl id="name">
                          <FormLabel>Message</FormLabel>
                          <Textarea
                            borderColor="gray.300"
                            _hover={{
                              borderRadius: 'gray.300',
                            }}
                            placeholder="message"
                          />
                        </FormControl>
                        <FormControl id="name" float="right">
                          <Button variant="solid" bg="#A9CD56" color="white" _hover={{}}>
                            Send Message
                          </Button>
                        </FormControl>
                      </VStack>
                    </Box>
                  </Box>
                </WrapItem>
              </Wrap>
            </Box>
          </Box>
          
        </Flex>
        <Container    maxW="full" mt={0}>
        <Box maxW="400px" maxH="400px" mx="auto" mt={4} mb ={20} >
          <AspectRatio ratio={1}>
            <iframe
              src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3963.952912260219!2d3.375295414770757!3d6.5276316452784755!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b8b2ae68280c1%3A0xdc9e87a367c3d9cb!2sLagos!5e0!3m2!1sen!2sng!4v1567723392506!5m2!1sen!2sng'
            />
          </AspectRatio>
        </Box>
      </Container>

      </Container>
     
      <Footer />
    </div>
  )
}
