import React from 'react';
import {
  Box,
  Container,
  Stack,
  Text,
  Flex,
  Center,
  Heading,
  Highlight,
} from '@chakra-ui/react';
import bg from '../images/bg.jpeg';
import Tech from '../images/Tech.png';
import Tech2 from '../images/Tech2.png';
import Tech3 from '../images/Tech3.png';
import Tech4 from '../images/Tech4.png';
import Tech5 from '../images/Tech5.png';
import Footer from '../components/Footer';

const pageStyles = {
  background: `url(${bg})`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  width: '100vw',
  minHeight: '100vh', // Ensure the background covers all content
};

const Technology = () => {
  return (
    <div style={pageStyles}>
      <Container maxW="full" mt={0} centerContent overflow="hidden">
        <Container maxW="2xl" centerContent p={20} maxH="3xl"></Container>
        <Container maxW="65%" alignItems="center">
          <Stack direction={['column', 'row']} spacing="20px">
            <Box maxW={['100%', '40rem']}>
              <Heading lineHeight="tall" color="black">
                <Highlight
                  query=" Approach:"
                  styles={{ px: '2', py: '1', rounded: 'full', bg: '#EDFBE7' }}
                >
                  Transforming Healthcare Our Innovative Approach:
                </Highlight>
              </Heading>
              <Text
                fontWeight="normal"
                fontFamily="sans-serif"
                color="blackAlpha.900"
                fontSize={['1vw', '1vw', '1vw']}
              >
                TelHab is a comprehensive system designed to enhance the connection between patients and physiotherapists through a mobile application and a portal. It facilitates remote monitoring and follow-up between in-person weekly sessions, ensuring continuous support for patients.
              </Text>
            </Box>
            <Flex>
              <Box
                bg={`url(${Tech})`}
                backgroundSize="cover"
                backgroundPosition="center"
                width={['100%', '50%']}
                maxWidth={['100%', '700px']}
                p="250"
                mt={0}
                height={550}
                objectFit="cover"
                mb = {2}
              ></Box>
            </Flex>
          </Stack>
        </Container>
        <Container maxW="65%" centerContent>
          <Center
            h="100px"
            color="white"
            mb={20}
            borderRadius="md"
            boxShadow="md"
            bg="#EDFBE7"
            overflow="auto"
            maxW="xxl"
            p="5"
            height={200}
          >
            <Text
              fontWeight="normal"
              fontFamily="sans-serif"
              color="blackAlpha.900"
              fontSize={['1vw', '1vw', '1vw']}
            >
              Patients receive interactive home exercise instructions through their online patient portal. To maintain accountability, patients are encouraged to wear compatible wearables during exercise sessions. These wearables automatically update the patients' portals with their daily progress. The application is capable of pairing with various commercial wearables, such as cell phones and Fitbit devices. It collects and analyzes the exercise data, generating progress reports after each session, which are then forwarded to the physiotherapists for further follow-up.
            </Text>
          </Center>
          <Center
            h="100px"
            color="white"
            mb={20}
            borderRadius="md"
            boxShadow="md"
            bg="#EDFBE7"
            overflow="auto"
            maxW="xxl"
            p="5"
            height={200}
          >
            <Text
              fontWeight="normal"
              fontFamily="sans-serif"
              color="blackAlpha.900"
              fontSize={['1vw', '1vw', '1vw']}
            >
              By having a dedicated physiotherapist monitoring patients' portals regularly, a constant line of communication is established between the healthcare professional and the patients. This feature enables remote supervision of patients' daily performance, ultimately enhancing accountability and adherence to the prescribed exercise regimen.
            </Text>
          </Center>
        </Container>
        <Container
          maxW="65%"
          height={900}
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Stack direction={['column', 'row']} spacing="5px">
            <Box
              bg={`url(${Tech2})`}
              backgroundSize="cover"
              backgroundPosition="center"
              w={['100%', '300px']}
              h={['400px', '650px']}
              marginBottom={150}
              objectFit="cover"
            ></Box>
            <Box
              bg={`url(${Tech3})`}
              backgroundSize="cover"
              backgroundPosition="center"
              w={['100%', '300px']}
              h={['400px', '650px']}
            ></Box>
            <Box
              bg={`url(${Tech4})`}
              backgroundSize="cover"
              backgroundPosition="center"
              w={['100%', '300px']}
              h={['400px', '650px']}
            ></Box>
            <Box
              bg={`url(${Tech5})`}
              backgroundSize="cover"
              backgroundPosition="center"
              w={['100%', '300px']}
              h={['400px', '650px']}
            ></Box>
          </Stack>
        </Container>
        
      </Container>
      <Footer />
    </div>
  );
};

export default Technology;
