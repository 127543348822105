'use client'

import {
  Box,
  Container,
  SimpleGrid,
  Stack,
  Text,
  Flex,
  useColorModeValue,
} from '@chakra-ui/react'
import Logo from '../images/logo.png';


const Logos = (props) => {
  return (
    <img
      src={Logo}
      alt="Logo"
      width={300} 
      height={300}
      {...props}
    />
  )
}

const ListHeader = ({ children }) => {
  return (
    <Text fontWeight={'500'} fontSize={'lg'} mb={2} color="#9AE6B4">
      {children}
    </Text>
  )
}

export default function LargeWithLogoCentered() {
  return (
    <Box
      bg={useColorModeValue('gray.100', 'gray.500')}
      color={useColorModeValue('gray.700', 'gray.200')}>
      <Container as={Stack} maxW={'6xl'} py={10}>
        
      </Container>
      <Box py={10}>
        <Flex
          align={'center'}
          _before={{
            content: '""',
            borderBottom: '1px solid',
            borderColor: useColorModeValue('gray.200', 'gray.700'),
            flexGrow: 1,
            mr: 8,
          }}
          _after={{
            content: '""',
            borderBottom: '1px solid',
            borderColor: useColorModeValue('gray.200', 'gray.700'),
            flexGrow: 1,
            ml: 8,
          }}>
          <Logos />
        </Flex>
        <Text pt={6} fontSize={'sm'} textAlign={'center'}>
          © 2023 Telhab. All rights reserved
        </Text>
      </Box>
    </Box>
  )
}