import React, { useState } from 'react';
import { Box, Flex, Link, Container, Image, Button, Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { ChevronDownIcon } from '@chakra-ui/icons';
import Logo from '../images/logo.png';
import bg from '../images/bg.jpeg';

const Navbar = () => {
  const [activeMenu, setActiveMenu] = useState('about'); // default active menu

  const navItems = [
    { name: 'About', path: '/about' },
    { name: 'Technology', path: '/technology' },
    { name: 'Sponsors', path: '/sponsors' },
    { name: 'Contacts', path: '/contacts' },
  ];

  return (
    <Box
      bg={`url(${bg})`}
      backgroundSize="cover"
      backgroundRepeat="no-repeat"
      backgroundPosition="center"
      p={4}
      boxShadow="xl"
    >
      <Container maxW="container.md">
        <Flex align="center" justify="space-between">
          
            <Image
              boxSize="80px"
              src={Logo}
              width={'1500'}
              height={'60'}
              alt="Telhab Logo"
              maxH="100px"
              mr ={10}
            />
         
          <Flex align="center">
            {navItems.map((item) => (
              <Link
                key={item.name}
                _hover={{ color: '#A9CD56' }}
                color={activeMenu === item.name.toLowerCase() ? '#A9CD56' : "#747C80"}
                fontFamily="heading"
                fontWeight="bold"
                fontSize="29"
                as={RouterLink}
                to={item.path}
                onClick={() => setActiveMenu(item.name.toLowerCase())}
                mr={4} // add right margin for spacing
                mt ={9}
              >
                {item.name}
              </Link>
            ))}
            <Box position="relative">
              <Menu>
                <MenuButton
                  as={Button}
                  rightIcon={<ChevronDownIcon />}
                  color={activeMenu === 'team' ? '#A9CD56' : "#747C80"}
                  _hover={{ color: '#A9CD56' }}
                  fontWeight="bold"
                  fontSize="29"
                  mt ={9}
                  onClick={() => setActiveMenu('team')}
                >
                  Team
                </MenuButton>
                <MenuList>
                  <MenuItem as={RouterLink} to="/team" _hover={{ background: 'blue.200' }}>Team</MenuItem>
                  <MenuItem as={RouterLink} to="/BusinessAdvisors" _hover={{ background: 'blue.200' }}>Business Advisors</MenuItem>
                </MenuList>
              </Menu>
              {/* Custom tab indicator */}
              <Box
                height="2px"
                bg="#A9CD56"
                position="absolute"
                bottom="0"
                left="0"
                right="0"
                display={activeMenu === 'team' ? 'block' : 'none'}
              />
            </Box>
          </Flex>
        </Flex>
      </Container>
    </Box>
  );
};

export default Navbar;
