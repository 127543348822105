import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ChakraProvider, CSSReset } from '@chakra-ui/react';
import Navbar from './components/Navbar'; // Import your Navbar component
import About from './components/About';
import Technology from './components/Technology';
import Team from './components/Team';
import Team2 from './components/Team2';
import Team3 from './components/Team3';
import Sponsors from './components/Sponsors';
import Contact from './components/Contact';


function App() {
  return (
   
    <ChakraProvider>
      <CSSReset />
      <Router>
        <Navbar /> {/* Include your Navbar component here */}
        
        {/* Route Configuration */}
        <Routes>
          <Route path="/" element={<About />} /> {/* This is the new default route */}
          <Route path="/about" element={<About />} />
          <Route path="/technology" element={<Technology />} />
          <Route path="/team" element={<Team />} />
          <Route path="/BusinessAdvisors" element={<Team2 />} />
          <Route path="/Board of Directors" element={<Team3 />} />
          <Route path="/sponsors" element={<Sponsors />} />
          <Route path="/contacts" element={<Contact />} />
        </Routes>
      </Router>
    </ChakraProvider>
  );
}

export default App;
