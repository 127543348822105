import pfp1 from '../images/pfp1.png';
import pfp2 from '../images/pfp2.png';
import pfp3 from '../images/pfp3.png';
import pfp4 from '../images/pfp4.png';
import pfp5 from '../images/pfp5.png';
import pfp6 from '../images/pfp6.png';
import pfp7 from '../images/pfp7.png';
import pfp8 from '../images/pfp8.png';
import pfp9 from '../images/pfp9.png';
import pfp10 from '../images/pfp10.png';
import pfp11 from '../images/pfp11.png';


const team1Info = [
  {
    avatarSrc: pfp1,
    name: 'Dr. Somayeh Norouzi-Gh',
    role: 'Chief Executive Officer & Co-Founder, PhD',
    Description: 'Co-Founder and CEO Postdoctoral fellow, University of Toronto Expertise: medical device design, robotics and control, qualitative studies, project management',
  },
  {
    avatarSrc: pfp2,
    name: 'Dr. Ali Mehrkish',
    role: 'Chief Financial Officer & Co-Founder, PhD',
    Description: 'PhD in mechanical Engineering, Toronto Metropolitan University Co-Founder and Business Developer',
  },
  {
    avatarSrc: pfp3,
    name: 'Dr. Ehsan Asadi',
    role: 'Senior Specialist & Co-Founder, PhD',
    Description: 'PhD in mechanical Engineering, University of Waterloo Co-Founder and Wearable Technology Specialist',
  },
  {
    avatarSrc: pfp4,
    name: 'Gabrielle Lee',
    role: 'Market Research Analyst',
    Description: 'BSc in Biomedical Physics, Toronto Metropolitan University Expertise: Market assessment ',
  },
  {
    avatarSrc: pfp5,
    name:  'Dr. Layla Hasandoost',
    role: 'Regulatory & Software Developer PhD',
    Description: 'PhD in biomedical engineering, Toronto Metropolitan University Expertise: quality assurance, regulatory affairs, front-end developer. ',
  },
  {
    avatarSrc: pfp6,
    name: 'Fatemeh Hassani',
    role: 'Senior Software Developer',
    Description: 'MSc in computer science, University of Waterloo Expertise: applications of software technologies, hardware programming, and robotics in healthcare',
  },
  {
    avatarSrc: pfp7,
    name: 'Saad Wasim',
    role: 'Software Developer',
    Description: 'Computer Engineering student,studying at Toronto Metropolitan University, with proficient skills in software development and Web development',
  },
  {
    avatarSrc: pfp8,
    name: 'Sogand Zamani Khah',
    role: 'Software Developer',
    Description: 'BSc in Computer Engineering, Sharif University Expertise: full-stack developer with two years of experience in designing web and mobile applications and agile methodologies.',
  },
];

const team2Info = [
  {
    avatarSrc: pfp9,
    name: 'Tarek Sadek',
    role: 'Faculty member, Affiliation: Executive director of MEIE at TMU  & Executive director of IBZ incubator',
    Description: 'head of MEIE at TMU and Executive Director of IBZ Incubator, [Name] drives innovation and entrepreneurship, fostering a culture of creativity and collaboration.',
  },
  {
    avatarSrc: pfp10,
    name: 'Gillian McCrea',
    role: 'Affiliation: Former venture manager of The Creative Destruction Lab at Halifax',
    Description: 'rings a wealth of experience in nurturing startups and driving disruptive innovation from their tenure as the Venture Manager at The Creative Destruction Lab in Halifax.',
  },
  {
    avatarSrc: pfp11,
    name: 'Ateeya Manzoor',
    role: 'Faculty member Affiliation: CEO of Ideation Clinic Hub, Founder of Cognosco Noor Ltd health incubator',
    Description: 'visionary leader, [Ateeya] serves as CEO of Ideation Clinic Hub and Founder of Cognosco Noor Ltd Health Incubator, dedicated to revolutionizing healthcare.',
  },
];



export default {
  team1Info,
  team2Info,
};
