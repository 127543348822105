import React from 'react';
import { Box, Heading, Text, Container, Highlight, } from '@chakra-ui/react';
import bg from '../images/bg.jpeg';
import Footer from '../components/Footer'; 
import TeamInformation from '../constants/TeamInformation';
import SimpleGridWrapper from '../components/wrappers/SimpleGridWrapper';

const pageStyles = {
  background: `url(${bg})`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',

  backgroundPosition: 'center',
  width: '100vw',
  minHeight: '100vh'
};

const Team = () => {
  return (
    <div style={pageStyles}>
      <Container maxW="xxxl" centerContent>
        <Box bg="gray.100" p="6" borderRadius="md" boxShadow="md" mt="6" ml="10">
          <Heading lineHeight="tall">
            <Highlight
              query="Meet the Team:"
              styles={{ px: '2', py: '1', rounded: 'full', bg: '#EDFBE7' }}
            >
              Meet the Team:
            </Highlight>
            <Text fontSize="lg" color="#424242" fontWeight="normal" textAlign="left">
              "Meet our dedicated team of experts, working passionately to turn your dreams into reality."
            </Text>
          </Heading>
        </Box>
        <Container  maxW="1800" mt="50">
          <SimpleGridWrapper teamInformation={TeamInformation.team1Info} />
        </Container>
      </Container>
      <Footer/>
    </div>
  );
};

export default Team;
